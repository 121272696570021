import { Container, IconButton, Typography, Grid2, Tooltip, Link, useMediaQuery } from '@mui/material/';
import './App.css';
import { useState } from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CircleIcon from '@mui/icons-material/Circle';
import Logo from './Logo.png';
import background from './background.png'
import building from './screenshots/building.png';
import endless from './screenshots/endless.png';
import perks from './screenshots/perks.png';
import upgrades from './screenshots/upgrades.png';
import powerups from './screenshots/powerups.png';
import biomes from './screenshots/biomes.png';
import ss1 from './screenshots/Screenshot1.png';
import ss2 from './screenshots/Screenshot2.png';
import ss3 from './screenshots/Screenshot3.png';
import ss4 from './screenshots/Screenshot4.png';
import ss5 from './screenshots/Screenshot5.png';
import ss6 from './screenshots/Screenshot6.png';
import ss7 from './screenshots/Screenshot7.png';
import ss8 from './screenshots/Screenshot8.png';
import ss9 from './screenshots/Screenshot9.png';
import './Bahiana.css';

function App() {
  const [image, setImage] = useState(0);

  const isMob = useMediaQuery('(max-width:1000px)');
  const isMob2 = useMediaQuery('(max-width:1700px)');

  document.body.style = 'background: #111213';

  const screenshots = [ss1, ss2, ss3, ss4, ss5, ss6, ss7, ss8, ss9];

  return (
      <div className='bg' style={{backgroundImage: `url(${background})`}}>
        <Container maxWidth="xl">
          {/*MENU*/}
          {isMob ? 
            <Grid2 container sx={{pt:3}} direction='column' alignItems={'flex-start'}>

              <img alt={"logo"} src={Logo} style={{width:'40%', marginBottom:5}}></img>

              <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', "&:hover": {color: "#df3c1d"}, fontSize:36.5}} href='https://store.steampowered.com/app/721990/Dead_Spawn/'>Purchase</Link>
              <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', "&:hover": {color: "#df3c1d"}, fontSize:36.5}} href='https://www.yzeegames.com/dead-spawn-press-kit/'>Press Kit</Link>
              <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', "&:hover": {color: "#df3c1d"}, fontSize:36.5}} href='https://www.yzeegames.com/contact/'>Contact</Link>

              <Grid2>
                <Tooltip title="Twitter"><IconButton href="https://x.com/DeadSpawnGame" sx={{ "&:hover": {color: "#df3c1d"}, color:'white'}}><TwitterIcon sx={{fontSize:25}}/></IconButton></Tooltip>
                <Tooltip title="YouTube"><IconButton href="https://www.youtube.com/c/YZeeGames" sx={{ "&:hover": {color: "#df3c1d"}, color:'white'}}><YouTubeIcon sx={{fontSize:25}}/></IconButton></Tooltip>
              </Grid2>
            </Grid2> 
            : <Grid2 container sx={{pt:3}} alignItems={'center'} justifyContent={'space-between'}>
                <Grid2 size={10} item container alignItems={'center'} justifyContent={'flex-start'}>

                    <img alt={"logo"} src={Logo} style={{marginRight:50}}></img>
                    
                    <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', "&:hover": {color: "#df3c1d"}, mr:6, fontSize:46.5}} href='https://store.steampowered.com/app/721990/Dead_Spawn/'>Purchase</Link>
                    <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', "&:hover": {color: "#df3c1d"}, mr:6, fontSize:46.5}} href='https://www.yzeegames.com/dead-spawn-press-kit/'>Press Kit</Link>
                    <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', "&:hover": {color: "#df3c1d"}, fontSize:46.5}} href='https://www.yzeegames.com/contact/'>Contact</Link>

                </Grid2>
                <Grid2 size={2} item container alignItems={'center'} justifyContent={'flex-end'}>

                    <Tooltip title="Twitter"><IconButton href="https://x.com/DeadSpawnGame" sx={{ "&:hover": {color: "#df3c1d"}, color:'white'}}><TwitterIcon sx={{fontSize:35}}/></IconButton></Tooltip>
                    <Tooltip title="YouTube"><IconButton href="https://www.youtube.com/c/YZeeGames" sx={{ "&:hover": {color: "#df3c1d"}, color:'white'}}><YouTubeIcon sx={{fontSize:35}}/></IconButton></Tooltip>

                </Grid2>
              </Grid2>
          }

          {!isMob2 && !isMob && <iframe allowFullScreen title={"trailer"} style={{marginTop: 280, width:'30vw', height:'16.95vw'}}  src='https://www.youtube.com/embed/y4_tgLmzTwQ'></iframe>}
          {isMob2 && !isMob && <iframe allowFullScreen title={"trailer"} style={{marginTop: 200, width:'50vw', height:'28.25vw'}}  src='https://www.youtube.com/embed/y4_tgLmzTwQ'></iframe>}
          {isMob && <iframe allowFullScreen title={"Trailer"} style={{marginTop: 50, width:'100%', height:'56.5vw'}}  src='https://www.youtube.com/embed/y4_tgLmzTwQ'></iframe>}

          <Grid2 container justifyContent={'center'} alignItems={'center'} sx={{mt: isMob ? 15 : isMob2 ? 45 : 90, mb:isMob ? 15 : 35}}>
            <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize:isMob ? 36.5 : 46.5, maxWidth:'md'}} color='white'>Dead Spawn is a fast-paced arena shoot ’em up, with building mechanics. Dead Spawn has rogue-like elements and is heavily inspired by Box Head Zombies.</Typography>
            {false && <iframe allowFullScreen title={"purchase"} style={{marginTop:45}} src="https://store.steampowered.com/widget/721990/" frameborder="0" width="646" height="190"></iframe>}
          </Grid2>

          <Grid2 container spacing={3} columns={{xs:1, sm:8, lg: 12}} >
            <Grid2 size={{xs: 1, sm:4, md:4}} justifyItems={'center'} justifyContent={'center'} alignItems={'center'}>
              <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize:isMob ? 36.5 : 46.5}} color='white'>Procedural Generation</Typography>
              <img alt={"screenshot1"} src={biomes} style={{width:'100%', marginTop:8}}></img>
            </Grid2>
            <Grid2 size={{xs: 1, sm:4, md:4}}>
              <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize:isMob ? 36.5 : 46.5}} color='white'>Building</Typography>
              <img alt={"screenshot2"} src={building} style={{width:'100%', marginTop:8}}></img>
            </Grid2>
            <Grid2 size={{xs: 1, sm:4, md:4}}>
              <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize:isMob ? 36.5 : 46.5}} color='white'>Perks</Typography>
              <img alt={"screenshot3"} src={perks} style={{width:'100%', marginTop:8}}></img>
            </Grid2>
            <Grid2 size={{xs: 1, sm:4, md:4}}>
              <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize:isMob ? 36.5 : 46.5}} color='white'>Powerups</Typography>
              <img alt={"screenshot4"} src={powerups} style={{width:'100%', marginTop:8}}></img>
            </Grid2>
            <Grid2 size={{xs: 1, sm:4, md:4}}>
              <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize:isMob ? 36.5 : 46.5}} color='white'>Upgrades</Typography>
              <img alt={"screenshot5"} src={upgrades} style={{width:'100%', marginTop:8}}></img>
            </Grid2>
            <Grid2 size={{xs: 1, sm:4, md:4}}>
              <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize:isMob ? 36.5 : 46.5}} color='white'>Endless</Typography>
              <img  alt={"screenshot6"} src={endless} style={{width:'100%', marginTop:8}}></img>
            </Grid2>
          </Grid2>

          <Grid2 sx={{mt:15, mb:15}} container direction={'column'} alignItems={'center'}>
            <Grid2 container>
              <Typography fontFamily={'Bahiana'} sx={{fontSize:isMob ? 36.5 : 46.5}} color='white'>Screenshots</Typography>
            </Grid2>

            <img alt={"screenshots"} src={screenshots[image]} style={{width:isMob ? '100%' : '65%', marginTop:15, marginBottom:10}}></img>

            <Grid2>
              <Tooltip title="Previous"><IconButton onClick={() => {setImage(image === 0 ? screenshots.length - 1 : image - 1)}} sx={{ "&:hover": {color: "#df3c1d"}, color:'white'}}><ArrowLeftIcon sx={{fontSize:35}}/></IconButton></Tooltip>
              {screenshots.map((_, index) => {
                return <IconButton sx={{color: index === image ? "#df3c1d" : 'white'}} onClick={() => {setImage(index)}}><CircleIcon sx={{fontSize:'small'}}/></IconButton>
              })}
              <Tooltip title="Next"><IconButton onClick={() => {setImage((image + 1) % screenshots.length)}} sx={{ "&:hover": {color: "#df3c1d"}, color:'white'}}><ArrowRightIcon sx={{fontSize:35}}/></IconButton></Tooltip>
            </Grid2>
          </Grid2>
          
        </Container>
  
        <Grid2 container sx={{backgroundColor:'white', paddingTop:3, paddingBottom:3}} direction={'column'} alignItems={'center'} marginBottom={50}>
            <Typography fontFamily={'Bahiana'} sx={{fontSize:24}} color='black'><strong>YZeeGames</strong> © 2016. All Rights Reserved</Typography>
            <Grid2 container sx={{mt:1.5}} direction={'row'}>
              <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'#df3c1d', fontSize:24}} href='https://store.steampowered.com/app/721990/Dead_Spawn/'>Steam</Link>
              <Typography fontFamily={'Bahiana'} sx={{fontSize:24, mr:1, ml: 1}} color='#df3c1d'>|</Typography>
              <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'#df3c1d', fontSize:24}} href='https://www.yzeegames.com/'>Main Site</Link>
              <Typography fontFamily={'Bahiana'} sx={{fontSize:24, mr:1, ml: 1}} color='#df3c1d'>|</Typography>
              <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'#df3c1d', fontSize:24}} href='https://www.yzeegames.com/dead-spawn-press-kit/'>Press Kit</Link>
              <Typography fontFamily={'Bahiana'} sx={{fontSize:24, mr:1, ml: 1}} color='#df3c1d'>|</Typography>
              <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'#df3c1d', fontSize:24}} href='https://www.yzeegames.com/press-kit/'>YZee Games Press Kit</Link>
              <Typography fontFamily={'Bahiana'} sx={{fontSize:24, mr:1, ml: 1}} color='#df3c1d'>|</Typography>
              <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'#df3c1d', fontSize:24}} href='https://www.yzeegames.com/contact/'>Contact</Link>
            </Grid2>
        </Grid2>  

    </div>
  );
}

export default App;
